.container {
  display: flex;
  background-color: #f4f4f4;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.logo {
  margin-bottom: 1.3rem;
  margin-top: 2rem;
}

.form_modal {
  width: 600px;
  background: var(--white);
  border: 1.8px solid #e3e5e7;
  padding: 2rem 2.5rem;
  border-radius: 20px;
}
.validation_message {
  font-size: 12.7px;
  font-family: 'Poppins';
  color: red;
}
.input_group {
  margin-top: 0.9rem;
}
.nav_btns a {
  display: block;
  font-weight: 500;
  font-size: 14.8px;
  font-family: 'Poppins';
  color: var(--label-color);
}
.nav_btns a:hover {
  color: var(--light-blue);
  font-weight: 600;
  text-decoration: underline;
}
.create_account_btn {
  background-color: var(--dark-blue);
  color: var(--white);
  height: 49px;
  padding: 0.4rem 0.7rem;
  outline: 0;
  display: inline-block;
  width: 170px;
  font-size: 14.5px;
  font-weight: 500;
  font-family: 'Poppins';
  border: 0;
  border-radius: 8px;
}
.nav_btns {
  margin-top: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.domains p:first-of-type {
  margin-top: 0.93rem;
  margin-bottom: 0.4rem;
  font-size: 14.7px;
  font-family: 'Poppins';
  color: var(--label-color);
}

.domains_category {
  display: flex;
  flex-wrap: wrap;
  flex-direction: colum;
  gap: 1.5rem;
}

.input_group_row {
  display: flex;
  gap: 1.5rem;
}
.input_group_col {
  flex: 1;
}

.form_modal h1 {
  white-space: nowrap;
  font-size: 31px;
  font-weight: 600;
  color: var(--dark-blue);
  text-align: center;
  font-family: 'Gilroy';
}

.form_modal p {
  color: var(--label-color);
  font-weight: 500;
}

@media screen and (min-width: 1000px) {
  .domains_category {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
