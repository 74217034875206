.container {
  height: 100vh;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3rem;
  padding-top: 2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1.7rem;
  margin-top: 1.5rem;
}
.login_wrapper p {
  color: var(--label-color);
  font-family: 'Poppins';
  font-size: '13.8px';
  padding: 0;
}
.input_code {
  width: 48px;
  padding: 0.5em 0.2rem;
  outline: 0;
  height: 48px;
  font-weight: 600;
  color: var(--label-color);
  text-align: center;
  border-radius: 9px;
  font-size: 19px;
  border: 1px solid var(--label-color);
}

.user_exist {
  padding: 0;
  margin: 0.4rem 0 0.6rem 0;
  color: var(--label-color);
}
.user_exist a {
  margin-left: 0.3rem;
  display: inline-block;
  color: var(--light-blue);
  font-weight: 600;
}
.forgot_link:hover {
  text-decoration: none;
}

.login_btn button {
  background-color: var(--dark-blue);
  width: 100%;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  font-size: 14.8px;
  margin: 1.5rem 0 0.5rem 0;
  font-family: 'Poppins';
  border-radius: 8px;
  color: var(--white);
  font-weight: 600;
}
.content_wrapper {
  background-color: var(--white);
  width: 100%;
  padding: 2rem 1.73rem;
  border-radius: 10px;
}
.validation_message {
  color: red;
  font-size: 13px;
  font-family: 'Poppins';
  display: 'block';
  font-weight: 500;
}
.validation_message::first-letter {
  text-transform: capitalize;
}

.row input:focus {
  border: 1px solid var(--dark-blue);
}
.header {
  font-family: 20px !important;
  font-weight: 500;
  color: var(--dark-blue);
  font-family: 'Poppins';
}
.reset_link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.7rem;
  gap: 0.3rem;
}
.reset_link span {
  font-size: 14px;
  color: var(--label-color);
  font-weight: 400;
  display: block;
  font-family: 'Poppins';
}
.reset_link span:not(:first-of-type) {
  color: var(--dark-blue);
  text-decoration: underline;
  font-weight: 400;
  display: block;
  font-size: 14.8px;
}

@media screen and (min-width: 1000px) {
  .header {
    font-size: 30px;
    font-weight: 600;
    color: var(--dark-blue);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
  }

  .content_wrapper {
    width: 435px;
    padding: 2.1rem 2.3rem;
    border-radius: 30px;
    border: 1px solid #e3e5e7;
    background-color: var(--white);
  }
  .logo_wrapper {
    padding-top: 0;
  }
}
