#landing {
  margin-top: 11vh;
  .mobile-header{
    background-image: url("../../images/svg/pages/landing/mobile-bg.svg");
    background-position: 100% -100%;
    background-repeat: no-repeat; 
  }
  .top-background {
    background: rgba(37, 138, 255, 0.09);
    border-radius: 27px;
    min-height: 80vh;

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
      min-height: 350px;
    }

    .small {
      font-family: Gilroy;
      font-size: 13px;
      font-style: normal;
      // font-weight: 600;
      line-height: 20px;
      letter-spacing: 5.400000095367432px;
    }

    .text-muted {
      font-family: Mazzard;
      font-size: 22px;
      font-style: normal;
      letter-spacing: 0px;
      font-weight: 400;
      line-height: 26px;
    }

    .main-header {
      font-family: Gilroy;
      font-size: 45px;
      font-weight: bold;
      line-height: 59px;
      letter-spacing: -0.8999999761581421px;
    }

  }

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    min-height: fit-content;

    .select-text {
      min-width: 50%;

      &.text-primary {
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 1px 44px 64px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .lower-div {
    background: $white;
    box-shadow: 0px 44px 64px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;

    p {
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }

  .side-image {
    // position: relative;
    // right: -8.4rem;
    animation: mover 1s infinite alternate;

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
      // right: 0;
    }
  }

  .specilization-card {
    min-height: 100px;
    border: 1px solid #d9dbe9;
    box-sizing: border-box;
    border-radius: 10px;
    p{
      font-weight: 800;
      color: $greyScaleLabel;
    }
    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
      min-height: 50px;
      p{
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .sessions {
    .sessions-card {
      // background: url(.jpg);
      color: $white;

      &.one-on-one {
        background-image: url("../../images/svg/pages/landing/one-on-one-bg.svg");
      }

      &.group-session {
        background-image: url("../../images/svg/pages/landing/group-session-bg.svg");
        // group-session-bg
      }

      min-height: 651px;
      background-size: cover;
      left: 122px;
      top: 1651px;
      border-radius: 18px;
    }
  }

  .continent-div {
    background: #f7a32c38;

    .slick-arrow {
      position: absolute;
      top: -40px;
      background: #ffffff;
      /* colour 13 */

      border: 1px solid rgba(223, 223, 223, 0.7);
      box-sizing: border-box;
      border-radius: 10px;
      //padding: 20px;
      width: 58.93px;
      height: 60px;
      z-index: 1;
      margin: auto;
      background: transparent;
      border-radius: 50%;
      border: 1px solid $black;
      color: $black;

      @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        display: none !important;
      }

      &.slick-prev {
        left: 85%;

        &::before {
          //   content: "←";
          content: url("../../images/svg/icons/ic_left.svg");
        }
      }

      &.slick-next {
        position: absolute;
        right: 2%;

        &::before {
          //   content: '→';
          content: url("../../images/svg/icons/ic_right.svg");
          color: black;
        }
      }
    }
  }

  .lower-slider {
    background: $creamWhite;

    .card {
      background: $white;
      /* colour 13 */

      border: 1px solid rgba(223, 223, 223, 0.7);
      box-sizing: border-box;
      border-radius: 15px;
    }

    .slick-arrow {
      position: absolute;
      top: -40px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      width: 58.93px;
      height: 60px;
      z-index: 1;
      margin: auto;
      background: $white;
      border-radius: 16px;
      color: $black;

      &:hover {
        background-color: $lightBlue;
      }

      @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        display: none !important;
      }

      &.slick-prev {
        left: 85%;

        &:hover {
          &::before {
            content: url("../../images/svg/icons/ic-less-than.svg");
          }
        }

        &::before {
          //   content: "←";
          content: url("../../images/svg/icons/ic-less-than-dark.svg");
        }
      }

      &.slick-next {
        position: absolute;
        right: 2%;

        &:hover {
          &::before {
            content: url("../../images/svg/icons/ic-greater-than.svg");
          }
        }

        &::before {
          //   content: '→';
          content: url("../../images/svg/icons/ic-greater-than-dark.svg");
        }
      }
    }

    .bottom {
      background: rgba(37, 138, 255, .1);
      border-radius: 15px;
    }
  }

  #can-do {
    background-color: #EBF4FF;
    border-radius: 27px;
  }
}

.landing-mid-header {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 58px;

  letter-spacing: -1px;

  color: $darkBlue;
}

.landing-large-header {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  // line-height: 84px;
  letter-spacing: -1.68821px;
  color: $darkBlue;
}

.btn-div {
  min-width: 20%;
}

.input-search {
  width: 60%;
  background-color: $creamWhite;
  padding-top: 20px;
  padding-bottom: 20px;

  &:focus-within {
    border: 2px solid $lightBlue;
  }

  input {
    border: none;
    outline: none;
    background-color: inherit;

    &::placeholder {
      text-align: center;
      // color: white;
    }
  }

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    width: 100%;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}