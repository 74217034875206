.accordion {
  border: 1px solid #c6c6c6;
  width: 700px;
  margin: 2rem 0;
  border-radius: 10px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question_text {
  color: #22215b;
  font-weight: 500;
  align-self: center;
  font-size: 1.35rem;
}
.accordion_content {
  padding: 1.6rem 1.5rem;
  border-bottom: 1px solid #c6c6c6;
}

.accordion_content:last-of-type {
  border: none;
}
.accordion_header {
  display: flex;
  justify-content: space-between;
}
.answer {
  font-size: 0.89rem;
  color: #636363;
}
.dropdown_icon {
  font-size: 2.5rem;
  display: block;
  align-self: flex-start;
  color: #22215b;
  transform: rotate(0);
  transition: all 0.5s ease;
}

.dropdown_active {
  color: #22215b;
  /* align-self: flex-start; */
  display: block;
  font-size: 2.5rem;
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.sub_answers {
  padding: 0;
  margin: 0;
  list-style-position: inside;
  margin: 0.8rem 0;
}
.sub_answers li {
  color: #747474;
  font-size: 0.88rem;
}

.sub_answers li:not(:first-of-type) {
  margin-top: 0.8rem;
}
