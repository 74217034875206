.container {
  padding: 2rem 5rem;
  margin-top: 85px;
}
.accordion_container {
  margin: 3rem 0;
}
.header {
  text-align: center;
  font-size: 2rem;
  color: #22215b;
  font-weight: 600;
}

.section_desc {
  text-align: center;
  font-size: 3.5rem;
  color: #22215b;
  font-weight: 600;
  margin: 1.2rem 0;
}
@media screen and (max-width: 650px) {
  .container {
    padding: 1.7rem 1.5rem;
  }
  .section_desc {
    font-size: 1.9rem;
    line-height: 1.4;
  }
  .header {
    font-size: 1.7rem;
  }
}
