.table-card{
    background: $white;
    box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
    border-radius: 16px !important;
    border: none !important;

    .table{
        td{
            border: none !important;
        }
    }
}