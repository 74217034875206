// .bookings-img{
//     max-height: 219.6px;;
//     width: 100%;
//     max-width: 366px;
// }
.messaging-div{
    background: #EFF0F6;
    border-radius: 16px;
    min-height: 506px;
    .messages{    
        max-height: 430px;
        height: 100%;
        overflow: auto;
        .user-avatar{
            border-radius: 50%;
        }
        .user-div{
            background-color: $white;
            border-radius: 8px 8px 8px 0px;
        }
        .messager-div{
            background-color: #EEF7FE;
            border-radius: 8px 8px 0px 8px;
        }
    }
    .input-div{
        display: flex;
        // position: relative;
        // top: 420px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        input{
            outline: none;
            // border: none;
            box-shadow: none;
            width: 100%;
            border-bottom-left-radius: 12px;
            border: 1px solid #C0C0C0;
            border-top-left-radius: 12px;
            padding: 8px 15px;
            &::placeholder{
                opacity: 0.2;
            }
        }
        button{
            background-color: $lightBlue;
            border-bottom-right-radius: 12px;
            border-top-right-radius: 12px;
            padding: 8px 15px;
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
}