$white: #fff;
$black: #000;
$yellow: #f7a32c;
$darkBlue: #22215b;
$fontGrey: rgba(34, 33, 91, 0.6);
$grayScale: #4e4b66;
$greyScaleLabel: #6e7191;
$lightBlue: #258aff;
$creamWhite: #f9fafb;
$mainBg: #f7fafe;
$success: #00ba88;
$light-grey: #F8FAFC;
$button-bg: #F5F6F7;
$button-text: #9A9EA7;
$grayScalePlaceholder: #A0A3BD;

$sidebar-width: 280px;

$all-transition: all 0.5s ease;

// CUSTOM THEME
$theme-colors: (
  "white": $white,
  "yellow": $yellow,
  "darkBlue": $darkBlue,
  "success": $success,
  "grayScale": $grayScale,
  "greyScaleLabel": $greyScaleLabel,
  "lightBlue": $lightBlue,
  "fontGrey": $fontGrey,
  "light-grey": $light-grey,
  "grayScalePlaceholder": $grayScalePlaceholder
);
