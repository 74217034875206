#main {
  margin-left: $sidebar-width;
  height: 100vh;
  overflow: auto;
  background-color: $mainBg;
  transition: $all-transition;
  &.main-toggled{
    margin-left: 0;
  }
  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    // Sidebar default state (on mobile)
    margin-left: 0;
  }
  .page-header{
    font-size: 36px;
    line-height: 42px;
    
    color: #0C1014;
    
  }
}

.btn{
  border-radius: 8px !important;
}
/* Navbar Hamburger animation */

#hamburger-icon {
  width: 30px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;
  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    display: block;
  }
}

#hamburger-icon span {
  display: block;
  height: 2px;
  width: 100%;
  background: $yellow;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  margin-top: 5px;
}


/* Close Icon */

#hamburger-icon {
  padding: 2px;
}

#hamburger-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon.open span:nth-child(1) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
  left: 1px;
  position: absolute;
}

#hamburger-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 22px;
  left: 1px;
  position: absolute;
}