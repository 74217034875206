.cursor-pointer {
    cursor: pointer;
}

.rounded {
    border-radius: 12px !important;
}

.navbar-toggler-icon {
    background-image:  url("../images/svg/icons/burger-icon.svg") !important;
}

.external-session-details{
    padding-top: 70px !important;
    min-height: calc(100% - 70px);
    .card{
        box-shadow: none !important;
    }
}

.minh-100{
    min-height: 100%;
}