.spinner {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 3.4px;
  height: 25px;
  width: max-content;
}

.split {
  width: 5px;
  animation: spinner 1200ms infinite ease-in-out;
  border-radius: 4px;
}
.split:nth-child(2) {
  animation-delay: -1100ms;
}
.split:nth-child(3) {
  animation-delay: -1000ms;
}
.split:nth-child(4) {
  animation-delay: -900ms;
}
.split:nth-child(5) {
  animation-delay: -800ms;
}
.split {
  background-color: var(--white);
}

@keyframes spinner {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
