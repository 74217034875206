#payment-page{
    // position: fixed;
    // height: 100vh;
    width: 100%;
    // z-index: 10000;
    // top: 0;
    // background-color: #E5E5E5;
    #payment-form{
        max-width: 550px;
        width: 100%;
        margin: auto;
        .CardField-input-wrapper, .CardBrandIcon-container, .CardField{
            display: block !important;
        } 
        #card-element{
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.payment-card{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    min-width: 50%;
    margin-right: 4px;
    &.selected{
        box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.15);
        border-color: $lightBlue;
    }
    input{
        display: none;
    }
}