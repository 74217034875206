.box {
  width: 463px;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem 1.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  box-shadow: 1.7px 2px 6.3px rgba(0, 0, 0, 0.3);
}
.container {
  padding: 2rem 1.2rem;
}
.img {
  padding: 0 0 0.6rem 0;
}
@media screen and (min-width: 1000px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    width: 100%;
  }
}

.header {
  font-size: 1.8rem;
  color: #22215b;
  text-align: center;
  line-height: 1.45;
  font-weight: 600;
}
.login {
  display: block;
  width: 100%;
  padding: 0.6rem 0.9rem;
  width: 180px;
  background-color: rgb(10, 10, 108);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  margin: 1.7rem 0;
  transition: all 0.3s ease-in-out;
  font-size: 1.09rem;
  font-weight: 500;
  align-self: center;
}
.login:hover {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.icon {
  font-size: 1.5rem;
  color: #fbba08;
  align-self: center;
  /* color: #258aff; */
}
