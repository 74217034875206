footer{
    background-color: $creamWhite;
    position: relative;
    width: 100%;
    // top: clamp(100vh, 100vh, 100%);
    i{
        font-size: 30px;
        margin-right: 10px;
        padding: 3px;
        margin-bottom: 5px;
    }
}