#profile{
    .card{
        box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
        border-radius: 16px;
        &.user-info{
            height: 252px;
            // height: 100%;
            .background-header{
                height: 50%;
                background-image: url("../../images/backgrounds/Pages/Profile/profile-header.png");
                background-size: cover;
                background-repeat: no-repeat;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }
            .image-div{
                min-width: 150px;
                margin-top: -60px;
                img{
                    border: 2px solid $white;
                    border-radius: 50%;
                    max-width: 150px !important;
                    width: 100%;
                    height: 100%;
                    min-height: 140px;
                }
            }
        }
        .details-footer{
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
}