.expert-group-cards {
  &.full-track {
    .slick-track {
      width: 100% !important;
    }
  }


  .slick-slide {
    // margin: 10px !important;
    max-width: 290px;
  }

  .session-card {
    color: $black;
    box-shadow: 0px 2px 4px rgba(14, 86, 124, 0.165308);
    box-sizing: border-box;
    min-height: 320px;
    // filter: drop-shadow(0px 54px 100px rgba(10, 4, 60, 0.1));
    border-radius: 16px;

    &:hover {
      border: 1px solid $lightBlue;
    }

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
    }

    .status {
      // font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 13px;
      line-height: 16px;
    }

    p {
      color: $greyScaleLabel;

      &.small {
        font-size: 13px !important;
      }
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 118%;
    /* or 17px */
  }

  font-family: 'Gilroy';


  letter-spacing: 0.0168em;
  font-feature-settings: 'tnum' on,
  'lnum' on;

  .inner {
    height: 315px;
    border-radius: 16px;
    position: relative;
    background-size: cover;

    h5 {
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }




    .img-div {
      display: flex;
      align-items: flex-start;
      justify-content: end;
    }

    .lower-half {
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 16px;
      width: 100%;
      position: absolute;
      bottom: 0;
      // position: relative;
      // top: 20%;

    }
  }

  .floating-card {
    background: $white;
    box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
    border-radius: 16px;
  }
}

.bg-pending {
  background: #f5a62326;
  color: #f5a623;
}

.bg-full {
  background: #0fbc491a;
  color: #0fbc49;
}

.expert-group-head{
  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'md') - .02px}) {
    font-size: 20px;
  }
}