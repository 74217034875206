.domain-component{
    .top-domain {
        background: #eef7fe;
        border-radius: 5px;
      }
    
      .user-domain-div{
        background: #FFFFFF;
        border: 1px solid #C0C0C0;
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 200px;
        width: 100%;
        .domain{
            background-color: $lightBlue;
            border-radius: 5px;
        }
        input{
            outline: none;
            border: none;
        }
      }
}