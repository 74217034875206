.container {
  height: 100vh;
  padding-top: 2rem;
  background-color: #ffff;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3rem;
}
.forgot_link {
  display: block;
  text-align: center;
  font-weight: 500;
  color: var(--label-color);
  margin-top: 0.2rem;
  font-size: 16px;
  text-decoration: none;
}
.user_exist {
  padding: 0;
  margin: 0.4rem 0 0.6rem 0;
  color: var(--label-color);
}
.user_exist a {
  margin-left: 0.3rem;
  display: inline-block;
  color: var(--light-blue);
  font-weight: 600;
}
.forgot_link:hover {
  text-decoration: none;
}

.login_btn button {
  background-color: var(--dark-blue);
  width: 100%;
  border: 0;
  outline: 0;
  height: 49px;
  font-size: 14.7px;
  margin: 1.75rem 0 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
  border-radius: 7px;
  color: var(--white);
  font-weight: 500;
}
.content_wrapper {
  width: 100%;
  padding: 1rem 1.73rem;
  background-color: #ffff;
}
.validation_message {
  color: red;
  font-size: 13px;
  font-family: 'Poppins';
  display: 'block';
  font-weight: 500;
}
.validation_message::first-letter {
  text-transform: capitalize;
}

.header {
  font-size: 27px;
  font-weight: 600;
  color: var(--dark-blue);
  font-family: 'Poppins';
}
@media screen and (min-width: 1000px) {
  .header {
    font-size: 32px;
    font-weight: 600;
    color: var(--dark-blue);
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 0;
    background-color: #f4f4f4;
  }
  .content_wrapper {
    width: 530px;
    padding: 2.4rem 3rem;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #e3e5e7;
  }
}
