#about {
  margin-bottom: 300px;

  .top {
    background-image: url("../../images/backgrounds/Pages/About/about-bg.jpg");
    // min-height: 100vh;
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;

    @media (min-width: 1px) and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
      .container {
        height: 100vh
      }
    }

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
      .container {
        height: 100%
      }
    }
  }

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    margin-bottom: 70px;
    margin-top: 70px;
  }

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'md') - .02px}) {
    // margin-top: 150px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    /* or 36px */

    letter-spacing: 0.0168em;
    font-feature-settings: "tnum" on, "lnum" on;
  }

  .contact {
    .card {
      background: $white;
      border: 1px solid #d9dbe9;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 0px 48px 100px rgba(10, 4, 60, 0.1);
      border-radius: 30px;
    }
  }
}