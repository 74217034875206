#tabs{
    .btn{
        outline: none !important;
        border: none !important;
        box-shadow: none !important;

    }
    .active{
        filter: drop-shadow(0px 1px 4px rgba(68, 68, 79, 0.1));
        background-color: $white !important;
        border-radius: 10px;
    }
}

.explore-tab{
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.1px;
    color: #171725;
    cursor: pointer;
    transition: $all-transition;
    &.active{
        border-bottom: 4px solid $lightBlue;
    }
    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        font-size: 24px;
      }
}