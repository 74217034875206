#transactions{
    .table{
        .status-pending{
            background-color: #FBFBEC;
            color: $yellow;
        }
        .status-paid{
            background-color: #ECFBF5;
            color: $success;
        }
    }
}