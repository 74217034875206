.container {
  display: flex;
  align-items: center;
  background-color: rgb(238, 247, 254);
  height: 56px;
  gap: 1.8rem;
  max-width: 160px;
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
}
.icon_wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  background-color: var(--dark-blue);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon {
  color: var(--white);
  font-size: 12px;
}
.container span {
  font-size: 13px;
  color: var(--dark-blue);
  font-weight: 500;
  font-family: 'Poppins';
  text-overflow: ellipsis;
  overflow: hidden;
}
