.g-2{
    font-family: 'Gilroy2';
}
.g3{
    font-family: 'Gilroy3';
}
.g-4{
    font-family: 'Gilroy4';
}
.g-6{
    font-family: 'Gilroy6';
}
.g-7{
    font-family: 'Gilroy7';
}
.g-8{
    font-family: 'Gilroy8';
}

#privacy-policy {
    font-weight: 600;
}