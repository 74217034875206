.container {
  padding: 3rem;
}
.hero {
  background-color: #fffaee;
  padding: calc(3vh + 3vw);
  background-color: #fffaee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  min-height: calc(30vh - 140px);
}
.text_desc {
  padding: 2rem 8rem;
  text-align: justify;
  line-height: 1.6;
}

table {
  width: 100%;
  color: #606060;
}
thead {
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
}
th,
td {
  padding: 0.8rem;
}
.policy h1 {
  font-size: 1.6rem;
}
tr {
  border-bottom: 1px solid #d4d4d4;
}
tr:last-of-type {
  border: 0;
}
.icon {
  font-size: 1.5rem;
  color: #333;
}
