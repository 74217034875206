#expert {
    margin-top: 100px;

    .top {
        height: 343px;

        .first-half {
            max-height: calc(343px/2);
            height: 100%;
            background-image: url("../../images/backgrounds/Pages/Profile/profile-header.png");
            background-size: cover;
            background-repeat: no-repeat;
            @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
                // Sidebar default state (on mobile)
                max-height: 100px;
              }
        }

        .image-div {
            img {
                border: 2px solid $white;
                border-radius: 50%;
                margin-top: -60px;
                max-width: 150px !important;
                max-height: 150px;
            }
        }

        .action {
            height: 56px;
            width: 56px;
            background: $white;
            // margin-right: 30px;
            /* Grayscale / Placeholder */
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            border: 1px solid $grayScalePlaceholder;
            box-sizing: border-box;
            border-radius: 16px;
        }
    }

    .review-content-tab {
        // display: flex;
        // align-items: center;
        // justify-content: center;

        #review-content {
            height: 500px;
            overflow: auto;
        }

        .content-area {
            // height: 480px;
            // overflow: auto;
        }
    }
}

.availability-box {
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 10px;
    color: #BDBDBD;
    border: 2px solid #BDBDBD;
    border-radius: 5px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed !important;
    }

    &.selected {
        color: $lightBlue !important;
        border: 2px solid $lightBlue !important;

    }
}

.confirm-date{
    border: 1px solid $greyScaleLabel !important;
    color: $grayScale !important;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
}

.rdp {
    .rdp-months {
        max-width: 100% !important;
        width: 100% !important;

        .rdp-table {
            max-width: 100% !important;
            width: 100% !important;
        }

        .rdp-month {
            width: 100%;
        }
        .rdp-weeknumber, .rdp-day{
            margin: auto
        }
    }
}

.book-modal-wrapper{
    min-height: 350px;
}