#sessions{
    .card{
        background: #FFFFFF;
        box-shadow: 0px 1.17603px 4.70412px rgba(23, 23, 37, 0.1);
        border-radius: 18.8165px;
        .information-div{
            border: 1.17603px solid #D9DBE9;
            box-sizing: border-box;
            border-radius: 18.8165px;
        }
        .copy-div{
            background: #EFF0F7;
            border: 0.757235px solid #C0C0C0;
            box-sizing: border-box;
            border-radius: 6.05788px;
        }
    }
}

.session-ended{
    background-image: url("../../images/backgrounds/Pages/review/review-bg.svg");
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-color: rgba(221, 237, 255, 1);
    padding: 20px;
    box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
    border-radius: 16px;
    min-height: 205px;
}

.select-div{
    display: flex;

    margin-bottom: 20px;
    div{
        padding: 10px;
        color: $greyScaleLabel;
        font-family: 'Gilroy';
        cursor: pointer;
        &.active{
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
            border-radius: 10px;
            color: $lightBlue;
            font-weight: bolder;
        }
    }
}

.form-review{
    p{
        color: #4A4A68;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Gilroy';
    }
}