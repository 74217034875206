.container {
  background-color: var(--white);
  border: 1px solid #e3e5e7;
  border-radius: 18px;
  width: 100%;
  padding: 2rem 2rem;
}
.modal h1 {
  font-size: 17px;
  text-align: center;
  color: var(--dark-blue);
  font-family: 'Poppins';
  font-weight: 600;
}

.image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem 0;
}
.nav_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.7rem;
}

.nav_button button {
  outline: 0;
  border: 0;
  background-color: var(--dark-blue);
  color: var(--white);
  padding: 0.73rem 0.7rem;
  width: 150px;
  font-size: 14px;
  border-radius: 7px;
  font-weight: 500;
  font-family: 'Poppins';
}

.login_nav h2,
.login_nav_active h2 {
  font-size: 20px;
  text-align: center;
  margin: 0.5rem 0;
  color: var(--light-blue);
  font-weight: 500;
}

.image_wrapper img {
  height: 97px;
}

.login_nav {
  border: 1.4px solid #e3e5e7;
  padding: 1.5rem 1.4rem;
  border-radius: 17px;
  transition: all 0.9s ease;
}
.login_nav_active {
  border: 1.4px solid var(--light-blue);
  padding: 1.5rem 1.4rem;
  border-radius: 17px;
  transition: all 0.9s ease;
  box-shadow: 0 2px 8px #d4d4d4;
}
.login_type {
  display: flex;
  margin-top: 2.4rem;
  gap: 2rem;
  flex-direction: column;
}

.login_nav p,
.login_nav_active p {
  font-size: 14px;
  color: var(--label-color);
  font-family: 'Poppins';
  font-weight: 300;
  line-height: 1.55;
}

@media screen and (min-width: 1000px) {
  .login_type {
    display: flex;
    margin-top: 2.4rem;
    gap: 2rem;
    flex-direction: row;
  }
  .container {
    width: 580px;
    padding: 2.2rem 2.5rem;
  }
  .modal h1 {
    font-size: 24px;
    max-width: 470px;
    line-height: 1.4;
  }
}
