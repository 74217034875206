@import 'antd/dist/antd.css';

.ant-select-selector{
    border: none !important;
}

.ant-select-focused{
    .ant-select-selector{
        border-color: transparent !important;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none !important;
    border-color: transparent !important;
}