:root {
  --light-blue: #258aff;
  --white: #fff;
  --label-color: #808080;
  --black: #00000099;
  --dark-blue: #22215b;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-Light.otf');
  font-weight: 200;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/poppins/Poppins-Light.otf');
  font-weight: 200;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/poppins/Poppins-Regular.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/poppins/Poppins-Medium.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/poppins/Poppins-Bold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/poppins/Poppins-ExtraBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-Regular.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-Medium.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-ExtraBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/gilroy-family/Gilroy-Heavy.ttf');
  font-weight: 800;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
label {
  font-family: 'Poppins';
}

input {
  font-family: 'Poppins';
  font-size: 16.3px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy';
}

button {
  font-family: 'Poppins';
}
