.container {
  padding: 3rem 1.5rem;
}
.section_one_header h1 {
  color: #22215b;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}
.container p {
  margin: 2rem 0;
  color: #686868;
}
.text_underline {
  text-decoration: underline;
}
.board_members h1,
.guiding_culture h1 {
  text-align: center;
  text-align: center;
}

.board_member {
  position: relative;
}
.board_member h2 {
  color: #615f5f;
  font-size: 1.3rem;
}
.board_member span {
  display: block;
  line-height: 2;
  font-size: 1.15rem;
  color: rgba(34, 33, 91, 0.6);
}
.board_member::before {
  position: absolute;
  content: "";
  height: 30px;
  top: 0;
  left: -0.7rem;
  border-left: 2px solid rgb(0, 170, 255);
}

@media screen and (min-width: 1000px) {
  .section_one_header {
    margin-top: 4rem;
    text-align: center;
  }
  .section_one_header h1 {
    font-size: 4rem;
    font-weight: 600;
    color: #22215b;
    line-height: 1.12;
  }
  .container {
    padding: 7rem 10rem 2rem 10rem;
  }
  .container p {
    margin: 2.6rem 0;
    text-align: justify;
    color: #686868;
  }
  .users_description {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  .right p {
    border-left: 1px solid #606060;
    padding-left: 1.5rem;
  }
  .board_members {
    margin: 2rem 0 3rem 0;
  }
  .board_members h1,
  .guiding_culture h1 {
    font-size: 4rem;
    color: #22215b;
    font-weight: 600;
  }
  .board_content {
    margin: 2rem 0 4rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4.5rem;
  }
  .board_member::before {
    left: -1.14rem;
  }

  /* .board_member::before {
    position: absolute;
    content: "";
    height: 30px;
    border-left: 2px solid rgb(0, 119, 255);
  } */
  /* .board_member h2 {
    position: absolute;
    left: 1.3rem;
    top: 0rem;
    font-size: 1.43rem;
  }
  .board_member span {
    display: block;
    position: absolute;
    left: 1.3rem;
    top: 2.35rem;
    font-size: 1.17rem;
    color: #6f6f6f;
  } */
}
