.select_component {
  width: 100%;
  outline: 0;
  border: 1px solid rgb(208, 201, 201);
  border-radius: 7px;
  padding: 1rem 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin-top: 1.3rem;
}
.select_component:hover {
  transition: all 0.3s ease-in-out;
  border: 1px solid rgb(10, 120, 255);
}
.option {
  padding: 3rem;
}
.paragraph {
  line-height: 1.18;
  color: #333;
  font-size: 0.93rem;
}
