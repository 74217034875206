#notification{
    .activity{
        box-shadow: (227, 230, 236, 1);
        // min-height: 532px;
        .data-div{
            overflow: auto;
            max-height: 500px;
            padding-right: 30px;
        }
    }

    .switch{
        width: 35px;
        height: 20px;
        background-color: #cccccc;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        div{
            width: 20px;
            height: 20px;
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0 0 8px -1px $black;
        }
    }
    .active-switch{
        justify-content: flex-end;
        background-color: $lightBlue;
    }

    .phone-input{
        border: 1px solid #C0C0C0;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px;
        input{
            border: none;
            outline: none;
        }
    }
}