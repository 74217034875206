


// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;


// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;


@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-Light.otf);
  font-weight: 200;
}

@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-Regular.ttf);
  font-weight: 300;
}

@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-Medium.ttf);
  font-weight: 400;
}

@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-Bold.ttf);
  font-weight: 600;
}

@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-ExtraBold.otf);
  font-weight: 700;
}

@font-face{
  font-family: 'Gilroy';
  src: url(../../fonts/gilroy-family/Gilroy-Heavy.ttf);
  font-weight: 800;
}
// *{
//   font-family: Gilroy;
// }