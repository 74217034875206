.search-input{
    background-color: $white;
    border: 1px solid $lightBlue;
    box-sizing: border-box;
    border-radius: 8px;
    height: 64px;
    input{
        width: 100%;
        box-shadow: none;
        border: none;
        outline: none;
        height: 100%;
    }
}

.search-dropdown{
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 8px;
    height: 64px;
}