#signup {
  #main{
    margin-left: 0 !important;
    // height: fit-content;
  }
  #sidebar {
    text-align: center;
    padding: 10px;
    overflow: hidden;
    .item {
      /* Dark blue */

      border: 1px solid $darkBlue;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 10px;
    }
  }
  .card {
    border-radius: 18px;
    border-color: $white;
  }

  .upload-div {
    background: rgba(192, 192, 192, 0.1);
    border: 2px dashed #c0c0c0;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 239px;
    width: 100%;
  }
}


.community-card {
  background: $white;
  border-radius: 16px;
  transition: $all-transition;
  border: 1px solid #d9dbe9;
  box-sizing: border-box;
  border-radius: 16px;
  &:hover,
  &.active {
    border: 1px solid $lightBlue !important;
    box-sizing: border-box;
    box-shadow: 9px 3.6px 36px rgba(0, 0, 0, 0.15) !important;
  }
}
