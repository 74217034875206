.custom-modal{
    .modal-content{
        border-radius: 16px !important;
        border-color: transparent !important;
        box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
    }
    h5{
        font-size: 24px;
        line-height: 34px;
        color: #1D293F;
        font-weight: 520;

    }
    .close-btn{
        background: $button-bg;
        color: $button-text;
        font-weight: 500;
        border-radius: 10px;
        font-size: 21px;
        opacity: 1;
        padding: 1px 10px !important;
        float: inherit !important;
        border-color: transparent;
    }
}