.booking-card{
    box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1);
    border-radius: 8px !important;
    border-color: transparent !important;
    .buttons{
        .btn{
            // box-shadow: 10px 4px 20px rgba(0, 0, 0, 0.15) !important;
            border-radius: 12px !important;
            &:focus, &:hover{
                box-shadow: none !important;
                outline: none !important;
            }
        }
        .btn-recall{
            background: #D5E8FF;
            border: 1px solid $lightBlue;
            color: $black !important;
            &:focus, &:hover{
                background-color: #D5E8FF !important;
                border: 1px solid $lightBlue;
            }
        }
        .btn-message{
            border: 1px solid $grayScalePlaceholder !important;
            box-sizing: border-box;
            border-radius: 12px;
            background-color: $white;
            color: $lightBlue !important;
            &:focus, &:hover{
                background-color: $white !important;
                border: 1px solid $grayScalePlaceholder !important;
            }
        }
    }

    .details-link{
        text-decoration: none;
    }
}

.card{
    box-shadow: 0px 1px 4px rgba(23, 23, 37, 0.1) !important;
    border-radius: 16px !important;
    border-color: $white !important;
    .bookings-inner-border{
        border: 1px solid #D9DBE9;
        box-sizing: border-box;
        border-radius: 16px;
    }
}

.star-container{
    svg{
        height: 20px !important;
        width: 23px !important;
    }
}

.disabled-Link {
    pointer-events: none;
    opacity: 0.6;
}