.custom-alert{
    border: 1px solid $lightBlue !important;
    box-shadow: 7px 15px 38px -18px rgba(23, 23, 37, 0.1) !important;
    border-radius: 16px !important;
    display: flex;
    align-items: center;
    button{
        margin-left: auto;
        background: $button-bg;
        color: $button-text;
        border-radius: 10px;
        border: none;
        outline: none;
        box-shadow: none;
    }
}