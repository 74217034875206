label {
  display: block;
  color: var(--label-color);
  font-weight: 400;
  font-size: 14.5px;
  margin: 0 0 0.3rem 0 !important;
}
label::first-letter {
  text-transform: capitalize;
}

input {
  font-family: 'Poppins';
  font-weight: 500;
}
.hidden_icon_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  border-top-right-radius: 6px;
}
.hidden_icon {
  font-size: 18px;
  color: var(--label-color);
}

.label_checkbox {
  font-size: 15.3px;
}
.input {
  width: 100%;
  outline: 0;
  border: 1px solid var(--label-color);
  padding: 0.75rem 0.8rem;
  border-radius: 6px;
  font-family: 'Poppins';
  /* box-shadow: 0 2px 4px 4px #7ba0ff; */
  font-weight: 400;
}

.input_wrapper {
  margin-top: 1rem;
}
.checkbox,
.checkbox_method {
  border: 1px solid #e3e5e7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  padding: 0.97rem 0.73rem;
  border-radius: 6px;
  width: 100%;
}

.password_wrapper {
  display: flex;
  border: 1px solid var(--label-color);
  overflow: hidden;
  border-radius: 6px;
}

.input,
.password_wrapper {
  height: 49px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 15px;
}
.password_wrapper input {
  border: 0;
  height: 100%;
  outline: 0;
  flex-grow: 2;
  padding: 0.75rem 0.8rem;
}

.input_text_area {
  height: 200px;
  width: 100%;
  outline: 0;
  border: 1px solid var(--label-color);
  padding: 1rem 0.8rem;
  border-radius: 6px;
  resize: none;
  font-family: 'Poppins';
}

textarea {
  resize: none;
  margin: 0;
  padding: 0;
}

.input:hover,
.password_wrapper:hover,
.input_text_area:hover {
  border: 1px solid rgb(12, 87, 218);
  transition: border 0.8s ease-in;
}
.checkbox_method {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .checkbox {
    width: 340px;
  }
  .checkbox_method {
    width: 250px;
  }
}
