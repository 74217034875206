#sidebar {
  position: absolute;
  width: $sidebar-width;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 0;
  bottom: 0;
  z-index: 10;
  overflow-y: scroll;
  background: $white;
  transition: $all-transition;

  .user-panel{
    background: #FAFAFB;
    border: 1px solid #E2E2EA;
    border-radius: 15px;
    .user-avatar{
      border-radius: 50%;
      width: 45px;
      height: 45px;
    }
  }
  .sidebar-nav{
    margin-top: 20px;
    list-style: none;
  }
  .sidebar-top-divider{
    margin-top: 80px !important;
  }

  .sidebar-item{
    cursor: pointer;
    margin-top: 20x;
    margin-bottom: 20px;
    svg{
      margin-right: 20px;
    }
    .sidebar-link{
      color: $grayScalePlaceholder;
      &:hover{
        text-decoration: none;
      }
    }
    &.active, &:hover{
      border-right: 2px solid $lightBlue;
      .sidebar-link{
        color: $lightBlue;
        svg{
          path{
            fill:  $lightBlue;
          }
        }
      }
    }
  }
  &.toggled {
    margin-left: -$sidebar-width;
  }

  @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
    // Sidebar default state (on mobile)
    margin-left: -$sidebar-width;

    // Sidebar toggled state (on mobile)
    &.toggled {
      margin-left: 0;
    }
  }
}
